import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import SEO from '../components/SEO'
import { AnimatedBox } from '../elements'


const contact = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="contact | Sneha Kattinti Photography" desc="Contact page for our website" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 10]}>
      
      <form class="gform pure-form pure-form-stacked" name="contact" method="post" action="https://script.google.com/macros/s/AKfycbxItk7ult3EpVgwMSLZYSA19nacP5S-l1vAldz1/exec"  data-email="sneha.inc.c@gmail.com">
      <fieldset class="pure-group">
        <label for="name">Name: </label>
        <input id="name" name="name" placeholder="Name" />
      </fieldset>

      <fieldset class="pure-group">
        <label for="email"><em>Your</em> Email Address:</label>
        <input id="email" name="email" type="email"  placeholder="your.name@email.com" />
      </fieldset>

      <fieldset class="pure-group">
        <label for="message">Message: </label>
        <textarea id="message" name="message" rows="10"
        placeholder="Tell us what's on your mind..."></textarea>
      </fieldset>

      <button class="button-success pure-button button-xlarge">
        <i class="fa fa-paper-plane"></i>&nbsp;Send</button>
         <tab> </tab>
      
        <button class="button-success pure-button button-xlarge" type="reset">
            <i class="fa fa-paper-plane"></i>&nbsp;Clear
        </button>
      </form>
      
      <script data-cfasync="false" type="text/javascript" src="../styles/form-submission-handler.js"></script>
      <script data-cfasync="false" type="text/javascript" src="../styles/gatsby-browser.js"></script>
    </AnimatedBox>
    </Layout>
    )
}

export default contact